/* You can add global styles to this file, and also import other style files */
$colorPrincipal: #1A3859;
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.info-btn{
  border: 1px solid #000;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.mat-expansion-panel-body {
  padding: 0px !important;
}
.pointer{
  cursor: pointer;
}
.btn-hb-blue {
  background: #1A3859;
  color: #FFF;
}
.btn-hb-blue:hover {
  background: #1A3859;
  color: #E5E5E5;
}
.btn-hb-yellow {
  background: #F0C000;
  color: #FFF;
}
.btn-hb-orange {
  background: #FF8700;
  color: #FFF;
}
.btn-hb-red {
  background: #9e0000;
  color: #FFF;
}
.btn-hb-green {
  background: #5C8001;
  color: #FFF;
}

.btn-gray {
  color: #fff;
  background-color: #6c757d;
}

.btn-blue {
  color: #fff;
  background-color: #007bff;
}

.btn-orange {
  color: #fff;
  background-color: #fd7e14;
}

//
.bg-hb-blue {
  background-image: linear-gradient(160deg, #457b9d, #1A3859, #1A3859);
  color: #FFF !important;
}
.bg-hb-yellow {
  background-image: linear-gradient(160deg, #ffd23f, #F0C000, #F0C000);
  color: #FFF !important;
}
.bg-hb-orange {
  background-image: linear-gradient(160deg, #ffba08, #FF8700, #FF8700);
  color: #FFF !important;
}
//
.po-input {
  background-color: transparent;
  box-shadow: inset 0 0px 0px 0;
  height: 49px;
  margin-top: -2px;
}
.po-input:hover {
  border: 2px solid #000 !important;
  box-shadow: 0px !important;
}
.po-input:focus {
  border: 1px solid #000 !important;
  box-shadow: 0px !important;
}
.po-field-icon {
  color: #000;
}
.po-disclaimer{
  .po-disclaimer-label {
    background-color: $colorPrincipal;
    color: #FFF;
  }
  .po-disclaimer-remove {
    background-color: $colorPrincipal;
    color: #FFF;
  }
}
.po-multiselect-checkbox-input-checked {
  .po-multiselect-checkbox-label:before {
    background-color: $colorPrincipal !important;
    border: 1px solid $colorPrincipal !important;
    box-shadow: none;
    color: #fff;
    font-family: PoIcon !important;
    line-height: 18px;
    padding-left: 1px;
  }
}

//
.expansion-panel-m-2{
  margin: 2%;
}
//
.example-section {
  margin: 12px 0;
}

.example-margin {
  margin: 0 12px;
}

ul {
  list-style-type: none;
  margin-top: 4px;
}
.w-logo {
  width: 9rem;
}
.img-user{
  width: 20%;
  border-radius: 50%;
  border: 7px solid #007b12;
}
.example-full-width{
  width: 100%;
}
//
.one-rem{margin-top: 1rem !important;}
.one-rem-left{margin-left: 1.19rem !important;}
//
.l-bg-card2 {
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  #1f3c5e 0%, #1A3859 90% );
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  color: #fff;
  cursor: pointer;
}

.box-shadow-card-blue{
  border: solid 2.8px #1f3c5e;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px, rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}
.bg-oculto {
  ::ng-deep .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    color: red !important;
  }
}
::ng-deep .bg-visisvel {
  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline{
    color: #1564B0 !important;
  }
}

.bg-editavel{
  ::ng-deep .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    color: #f1a309 !important;
  }
}

.text-center{
  text-align: center;
}
.mt-50-p{
  margin-top: 50%;
}
//
.card-timeline-in-prongress{
  background: #A5D4DE;
}
.card-timeline-check{
  background: #A5D4DE;
}
.card-timeline-not-progress{
  background: #A5D4DE;
}
.card-timeline-wait{
  background: #FCF2C7 ;
}
.card-timeline-success{
  background: #d0ffc0;
}
.card-timeline-success-change-date{
  background: #deffc0;
}
//
.txt-black{
  color: #000;
}
.m-t-button{
  margin-top: 0.8rem !important;
}
.m-l-button{
  margin-left: 0.8rem !important;
}
.mt-2rem{
  margin-top: 2rem !important;
}
.mb-1rem{
  margin-bottom: 1rem !important;
}
.w-40 {
  width: 40% !important;
}
.card-loader{
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  width: 40%;
}
.btn-blue-not-selected{
  background: #CAE0F7;
}
.btn-green-not-selected{
  background: #D0FFC0;
}
.btn-green{
  background: green;
  color: #FFF;
}
.btn-green-dark{
  background: #044804;
  color: #FFF;
}
.btn-blue-dark{
  background: #060448FF;
  color: #FFF;
}
.btn-blue-opacy{
  background: #325C73;
  color: #FFF;
}
.btn-green-opacy{
  background: #3e7332;
  color: #FFF;
}
//
.days-3{
  background-color: #00ED01;
}
.days-15{
  background-color: #3AF901;
}
.days-30{
  background-color: #87FA00;
}
.days-60{
  background-color: #CEFB02;
}
.days-90{
  background-color: #FEFB01;
}
.btn-in-input{
  position: absolute;
  top: -1.4rem;
  right: -3px;
  z-index: 999;
}
.w-5rem{
  width: 5rem;
}
.mat-standard-chip .mat-chip-remove {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0;
  background: none;
}

.mat-chip-remove .mat-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
}

::ng-deep .row-color1 {
  background-color: #dffadf;
}
::ng-deep .row-color2 {
  background-color: #daebee;
}
.wallpaper-card{
  background: url('assets/images/bg-profile.jpg');
  background-repeat: no-repeat;
  background-position: center;
}
